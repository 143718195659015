
@font-face {
  font-family: "Playfair Display";
  src: url("../../../assets/fonts/PlayfairDisplay-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Proxima Nova";
  src: url("../../../assets/fonts/Proxima-Nova-Light.otf") format("truetype");
}
.brand-banner__main {
  width: 100%;
  margin-top: 2px;
  height: 544px;
  .banner-content {
    position: relative;
    img {
      width: 100%;
      height: 100%;
    }
    .content-text {
      text-align: left;
      position: absolute;
      top: 150px;
      left: 68px;
      z-index: 3;
    }
    .banner-title {
      font-weight: 700;
      font-size: 48px;
      font-family: "Playfair Display";
      color: #12433d;
    }
    .banner-content-text {
      font-size: 24px;
      font-family: "Proxima Nova";
      width: 400px;
      line-height: 40px;
      margin-top: 50px;
      margin-bottom: 38px;
      color: #000000;
      font-weight: 600;
    }
    .banner-button-yellow {
      width: 268px;
      height: 43px;
      background-color: #fec206;
      text-align: center;
      border-radius: 22px;
      line-height: 40px;
      font-size: 18px;
      color: #ffffff;
      font-family: "Proxima Nova";
      font-weight: 600;
      cursor: pointer;
    }
    .banner-button {
      width: 268px;
      height: 43px;
      border: 2px solid #ff6132;
      text-align: center;
      border-radius: 22px;
      line-height: 40px;
      font-size: 16px;
      color: #000000;
      font-family: "Proxima Nova";
      cursor: pointer;
    }
    .banner-button:hover {
      background: #ff6132;
      color: white;
    }
    .left-content-text {
      // width: 100%;
      position: absolute;
      top: 97px;
      right: 68px;
      z-index: 3;
      margin-right: 0;
      .banner-content-text {
        text-align: right;
        width: 500px;
      }
      .two-text {
        margin-bottom: 56px;
      }
      .banner-button-yellow {
        float: right;
      }
    }
  }
  .banner-one {
    width: 510px;
    margin-top: -67px;
    //margin-bottom: -1em;
    line-height: 60px;
    //height: 20px;
  }
  .banner-three {
    height: 0;
  }
}
.ant-carousel .slick-dots li.slick-active {
  //width: 16px !important;
}
//.ant-carousel .slick-dots li {
//  margin: 0 7px !important;
//}
.ant-carousel .slick-dots li.slick-active .costom-pag-radius {
  background: #f5f4f4;
  margin-right: 0;
  width: 24px;
  height: 24px;
  margin-top: -19px;
}

.costom-pag-radius {
  height: 21px;
  width: 21px;
  border-radius: 50%;
  background: white;
  margin-top: -16px;
}

@media screen and (min-width: 1500px) {
  .slick-track {
    height: 544px !important;
  }
  .brand-banner__main .banner-content .left-content-text {
    right: 190px;
  }
  .brand-banner__main {
    .banner-content {
      .content-text {
        left: 183px;
      }
    }
  }
}
