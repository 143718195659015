
.bottom_text {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #1a1818;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 78px;
}
.click_text1 {
  font-weight: 700;
  color: #f15a42;
  cursor: pointer;
}
.top-banner-outer {
  overflow: hidden;
  background: rgba(207, 229, 204, 0.4);
  margin: 0px auto;
  position: relative;
}
.top-banner-info {
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 79px;
  margin-bottom: 77px;
}
.top-banner-elipse {
  position: absolute;
  right: 0;
  top: -30px;
  z-index: 20;
}
.top-banner-dot {
  position: absolute;
  right: 46px;
  top: -56px;
  z-index: 10;
}
.bf-package-layer {
  width: 82%;
  text-align: center;
  margin: 0 auto;
  //   max-width: 1181px;
  padding-top: 48px;
}
