
@font-face
  font-family: "Playfair Display"
  src: url("../../../assets/fonts/PlayfairDisplay-Bold.ttf") format("truetype")
@font-face
  font-family: "Proxima Nova"
  src: url("../../../assets/fonts/Proxima-Nova-Light.otf") format("truetype")
.brand-bottom-banner__main
  width: 100%
  height: auto
  margin: 111px 0 84px 0
  text-align: center
  .bg-main-style
    width: 100%
    height: auto
    z-index: 1
  .bottom-banner
    width: 100%
    z-index: 2
    min-height: 395px
    margin: 0 auto
    //background: linear-gradient(182.29deg, #E2E2E4 6.14%, #E9E9EB 24.08%, #E8E9EB 51.15%, #E7E8EA 72.15%, #E3E3E3 99.97%)
    background: url("../../../assets/img/brand_and_retailer_new_homepage/B&R_banner_buttom.png") #E8E9EB no-repeat left center
    background-size: cover
    .banner-text
      text-align: right
      overflow: hidden
      margin: 0 100px
      .banner-title
        font-size: 48px
        font-weight: 700
        font-family: "Playfair Display"
        color: #12433D
        //margin-bottom: 30px
        height: 60px
        line-height: 60px
        margin-top: 100px
        //margin-bottom: 50px
      .banner-title-two
        margin-top: 10px
        margin-bottom: 50px
      .banner-content
        font-size: 24px
        font-weight: 600
        line-height: 40px
        color: #000000
        font-family: "Proxima Nova"
      .banner-button
        float: right
        width: 268px
        height: 43px
        line-height: 39px
        text-align: center
        border: 2px solid #FF6132
        border-radius: 22px
        font-size: 16px
        font-family: "Proxima Nova"
        margin-top: 18px
        cursor: pointer
        color: #000000
      .banner-button:hover
        background: #FF6132
        color: white
@media screen and (min-width: 1500px)
  .brand-bottom-banner__main .bottom-banner .banner-text
    margin: 0 214px
