
.beauty-fluent-step-two__main {
  text-align: center;
  .step-title {
    width: 316px;
    text-align: center;
    margin: 50px auto 60px;
    display: flex;
    justify-content: space-between;
    position: relative;
    .step-line {
      height: 3px;
      width: 158px;
      background-color: #f15a42;
      position: absolute;
      top: 15px;
      left: 0;
      z-index: 1;
    }
    .no-step-line {
      background-color: rgba(237, 142, 131, 0.3);
      height: 3px;
      width: 158px;
      position: absolute;
      top: 15px;
      right: 31px;
      z-index: 0;
    }
  }
  .select-billing-frequency-title {
    font-size: 40px;
    color: #1a1818;
    font-family: DMSans;
    margin-bottom: 78px;
  }
  .billing-content {
    //margin-top: 48px;
  }
  .step-icon-style {
    position: absolute;
    top: -25px;
    left: -7px;
    color: #1a1818;
    font-weight: 500;
    font-family: DMSans;
  }
  .billing-style {
    left: 129px;
    color: #f27c59;
  }
  .payment-style {
    left: 260px;
  }
}
