
p {
  margin-bottom: 0;
}
.signup--section--hero-brand {
  background-color: #FFFBE7;
  height: 480px;
  padding: 40px;
  overflow-y: hidden;
}

.signup--section--hero-brand--inner {
  max-width: 1440px;
  margin: 0 auto;
}

.signup--section--hero-brand--content__wrapper {
  padding-left: 80px;
  padding-top: 80px;
}

.signup--section--hero-brand__title {
  font-family: Niveau Grotesk;
  font-style: normal;
  font-weight: normal;
  font-size: 65px;
  line-height: 60px;
  font-feature-settings: 'liga' off;
  color: #1A1818;
}

.signup--section--hero-brand__title.brands {
  color: #EE5E42;
}

.signup--section--hero-brand__title:nth-child(2) {
  margin-bottom: 40px;
}

.signup--section--hero-brand__content {
  max-width: 580px;
  font-family: Mr Eaves Mod OT;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 33px;
  color: #000000;
}

.signup--section--hero-brand--image__wrapper {
  position: relative;
}

.signup--section--hero-brand--image__wrapper .ellipse {
  position: absolute;
  top: 60px;
  right: 20px;
}

.signup--section--hero-brand--image__wrapper .dots--pattern {
  position: absolute;
  top: 40px;
  right: 60px;
}

.signup--section--hero-brand--image__wrapper .dots--pattern--2 {
  position: absolute;
  right: 420px;
  top: 230px;
}

.signup--section--hero-brand--image__wrapper .clean--precheck {
  position: absolute;
}

.signup--section--hero-brand--image__wrapper .clean--precheck--4 {
  right: 135px;
  top: 40px;
}

.signup--section--main--benefits {
  height: 100%;
  background-color: rgba(241, 90, 66, 0.2);
  font-family: Mr Eaves Mod OT;
}

.signup--section--main--benefits > div {
  padding: 80px 80px;
  max-width: 720px;
  margin-left: auto;
}

.signup--section--main--benefits h2 {
  margin-bottom: 40px;
  font-family: Mr Eaves Mod OT;
}

.signup--section--main--benefits li {
  font-family: Mr Eaves Mod OT;
  display: flex;
  align-items: flex-start;
  font-size: 20px;
  font-weight: 400;
  color: #1A1818;
}

.signup--section--main--benefits li:not(:last-child) {
  margin-bottom: 32px;
}

.signup--section--main--benefits li img {
  margin-right: 16px;
}

.signup--section--main--form > div {
  max-width: 720px;
  margin-right: auto;
  padding-top: 48px;
  padding-bottom: 80px;
  padding-left: 20px;
}

.signup--section--main--form > div section {
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

.signup--section--main--form > div section .form--title {
  text-align: left;
}

@media screen and (max-width: 1439px) {
  .signup--section--hero-brand--content__wrapper {
    padding-left: 12px;
  }

  .signup--section--main--benefits > div {
    padding: 80px 40px;
  }

  .signup--section--main--form {
    padding-right: 24px;
  }

  .signup--section--main--form > div {
    padding-left: 0;
  }
}

@media screen and (max-width: 1199px) {
  .signup--section--hero-brand--content__wrapper .signup--section--hero-brand__title {
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 55px;
  }

  .signup--section--hero-brand--image__wrapper .ellipse {
    position: absolute;
    top: 60px;
    right: 20px;
    width: 126.5px;
    height: 126.5px;
  }

  .signup--section--hero-brand--image__wrapper .dots--pattern {
    position: absolute;
    top: 40px;
    right: 60px;
    height: 96px;
    width: 127px;
  }

  .signup--section--hero-brand--image__wrapper .dots--pattern--2 {
    position: absolute;
    right: 250px;
    top: 230px;
    height: 96px;
    width: 127px;
  }

  .signup--section--hero-brand--image__wrapper .clean--precheck {
    height: 66px;
    width: 222px;
  }

  .signup--section--hero-brand--image__wrapper .clean--precheck--1 {
    right: 100px;
    top: 50px;
  }

  .signup--section--hero-brand--image__wrapper .clean--precheck--2 {
    right: 203px;
    top: 135px;
  }

  .signup--section--hero-brand--image__wrapper .clean--precheck--3 {
    right: 100px;
    top: 220px;
  }

  .signup--section--hero-brand--image__wrapper .clean--precheck--4 {
    right: 255px;
    top: 305px;
  }
}

@media screen and (max-width: 1023px) {
  .signup--section--main > .columns {
    display: block;
    margin: 0;
  }

  .signup--section--main > .columns > .column {
    padding: 0;
  }

  .signup--section--main--benefits > div {
    padding: 40px 20px;
    max-width: none;
    max-width: 600px;
    margin: 0 auto;
  }

  .signup--section--main--benefits .heading--4 {
    font-weight: 400;
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 20px;
  }

  .signup--section--main--form {
    padding-right: 0;
  }

  .signup--section--main--form > div {
    max-width: none;
    padding: 0;
  }

  .signup--section--main--form section {
    margin: 0 auto !important;
  }
}

@media screen and (max-width: 991px) {
  .signup--section--hero-brand {
    height: auto;
    padding: 40px 20px;
  }

  .signup--section--hero-brand--inner > .columns {
    display: block;
    margin: 0;
  }

  .signup--section--hero-brand--content__wrapper {
    padding: 0;
    text-align: center;
  }

  .signup--section--hero-brand__content {
    max-width: 400px;
    margin: 0 auto;
    font-size: 25px;
    line-height: 28px;
  }

  .signup--section--hero-brand--image__wrapper {
    height: 400px;
    max-width: 400px;
    margin: 0 auto;
  }

  .signup--section--hero-brand--image__wrapper .dots--pattern {
    position: absolute;
    top: 40px;
    right: 60px;
    height: 96px;
    width: 127px;
  }

  .signup--section--hero-brand--image__wrapper .dots--pattern--2 {
    position: absolute;
    right: 250px;
    top: 285px;
    height: 96px;
    width: 127px;
  }

  .signup--section--hero-brand--image__wrapper .clean--precheck--1 {
    right: 32px;
    top: 50px;
  }

  .signup--section--hero-brand--image__wrapper .clean--precheck--2 {
    right: 145px;
    top: 135px;
  }

  .signup--section--hero-brand--image__wrapper .clean--precheck--3 {
    right: 32px;
    top: 220px;
  }

  .signup--section--hero-brand--image__wrapper .clean--precheck--4 {
    right: 145px;
    top: 305px;
  }
}
