
.brand-catalog-upload-product-dialog {
  text-align: left;
  .retailer-location-upload-modal__action {
    margin: 10px auto;
    display: flex;
    justify-content: center;
  }
  .upload-modal__btn-upload {
    background-color: #4a8a5d;
    color: white;
  }
  .upload-modal__error-icon {
    color: red;
    margin-right: 10px;
  }
  .ant-upload-text {
    font-family: "Proxima Nova", "Roboto", sans-serif;
  }
  .upload-modal__btn {
    font-family: "Proxima Nova";
    font-weight: normal;
    font-size: 14px;
    margin: 0 auto;
    padding: 4px 20px;
    background-color: #4a8a5d;
    color: white !important;
  }
}
