
.beauty-fluent-step-three-account__main {
  max-width: 648px;
  margin-bottom: 48px;
  p {
    margin-bottom: 0;
  }
  .step-three-form {
    .ant-form-item {
      margin-bottom: 24px;
      .ant-input,
      .ant-input-affix-wrapper,
      .ant-input-password,
      .ant-input-affix-wrapper .ant-input {
        height: 55px;
        border-radius: 100px;
      }
    }
    .form-item-name {
      display: flex;
      justify-content: space-between;
    }
  }
  .billing-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
    font-family: "Niveau Grotesk";
  }
  .password-style {
    position: relative;
    .eye-style {
      position: absolute;
      top: 11px;
      right: 19px;
      z-index: 10;
      cursor: pointer;
    }
    .has-color {
      color: #f27c59;
    }
  }
}
