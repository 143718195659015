
#bf_package_plan_table .ant-table-bordered .ant-table-thead > tr > th,
#bf_package_plan_table .ant-table-bordered .ant-table-tbody > tr > td {
  border-right: 1px solid #cfe5cc;
}
#bf_package_plan_table
  .ant-table-middle
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr
  > th {
  padding: 26px 8px;
}
#bf_package_plan_table .ant-table-thead > tr > th {
  background: #cfe5cc;
  border-bottom: 1px solid #cfe5cc;
}
#bf_package_plan_table .ant-table-tbody > tr > td {
  border-bottom: 1px solid #cfe5cc;
}
#bf_package_plan_table .ant-table.ant-table-bordered .ant-table-footer {
  border: 1px solid #cfe5cc;
}
#bf_package_plan_table .ant-table-footer {
  background: rgba(207, 229, 204, 0.4);
}
#bf_package_plan_table .ant-select-selection {
  border: 1px solid #f15a42;
}
#bf_package_plan_table .ant-select-selection .ant-select-arrow-icon {
  color: #f15a42;
}
.bf_talbe__select_dropdown.ant-select-dropdown {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.02em;
  color: #8c8c8c;
}
.bf_talbe__select_dropdown
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled),
.bf_talbe__select_dropdown
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  background: #f15a42;
  color: white;
}
.bf_talbe__btn.ant-btn:hover,
.bf_talbe__btn.ant-btn:focus,
.bf_talbe__btn.ant-btn:active,
.bf_talbe__btn.ant-btn.active {
  background: #f15a42;
  color: white;
}
.bf_talbe__btn.ant-btn > span {
  text-transform: uppercase;
}
.bf_talbe__btn.ant-btn[disabled],
.bf_talbe__btn.ant-btn[disabled]:hover,
.bf_talbe__btn.ant-btn[disabled]:active,
.bf_talbe__btn.ant-btn[disabled]:focus {
  background: rgba(241, 90, 66, 0.4);
  color: white;
}
