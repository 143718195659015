
p {
  margin-bottom: 25px;
  //font-family: niveau-grotesk, sans-serif;
  font-family: "Mr Eaves Mod OT", sans-serif;
  font-size: 25px;
  font-weight: 300;
  line-height: 1.4;
  color: #000;
}
.terms {
  max-width: 783px;
  width: 85%;
  margin: 0 auto;
}
.terms__main-header {
  color: #12433d;
  font-size: 45px;
  font-weight: bold;
  font-family: "Playfair Display Bold";
  letter-spacing: 0.44px;
  margin-bottom: 40px;
  /*text-align: left;*/
  line-height: 1.2;
  text-align: center;
}
.terms__content {
  margin-bottom: 20px;
  font-family: "ProximaNova-Regular", "ProximaNova", "Roboto", sans-serif;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}
.terms-content-title {
  font-size: 25px;
  font-family: "Mr Eaves Mod OT", sans-serif;
  font-weight: 100 !important;
  margin-bottom: 60px;
  margin-top: 79px;
}
.terms-of-use__main {
  font-size: 65px;
  text-align: center;
  margin-bottom: 40px;
  color: #1a1818;
  /*font-family: niveau-grotesk, sans-serif;*/
}
.terms-privacy-data {
  margin-bottom: 59px;
  color: #91d395;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
}
.terms__link_color {
  color: #008489;
}
