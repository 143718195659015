
.guest-red-product-list-new__main {
  .line {
    width: 100%;
    height: 2px;
    margin-bottom: 25px;
    background: #d9d9d9;
  }
  .guest-product-list-new__content {
    margin: 0 auto;
  }
  .img-video-and-product-info-top {
    min-height: 300px;
    display: flex;
    justify-content: start;
    .show-img-video-component {
      width: 450px;
      margin-right: 50px;
    }
    .show-product-info {
      p {
        color: #000000;
        font-size: 12px;
        line-height: 22px;
      }
      .product-text-name {
        display: inline-block;
        width: 60px;
      }
    }
    .hero-tips-style {
      background: #f3ca50;
      color: #ffffff !important;
      border-radius: 100px;
      width: 39px;
      height: 22px;
      line-height: 22px;
      text-align: center;
      margin-top: 10px;
    }
  }
  .other-info-center-content {
    margin: 20px 0;
    display: flex;
    justify-content: start;
    .form-content {
      width: 450px;
      margin-right: 50px;
      color: #262626;
      font-family: "Proxima Nova", "Roboto", sans-serif;
    }
    .form-item-name {
      display: inline-block;
      width: 101px;
      text-align: left;
      margin-right: 24px;
    }
    .form-item-content {
      display: inline-block;
      width: 450px;
      min-height: 24px;
      background-color: #fafafa;
      border: 1px solid #d9d9d9;
      padding: 4px 5px;
      margin-bottom: 12px;
      margin-right: 20px;
      span,
      .span-cross-seller-text {
        margin-right: 4px;
        padding: 1px 8px;
        border: 1px solid #e2eeec;
        border-radius: 2px;
        background-color: white;
      }
      .span-cross-seller-text {
        margin-bottom: 2px;
        display: block;
      }
    }
    .center-key-benefits {
      //height: 221px;
      font-family: "Proxima Nova", "Roboto", sans-serif;
      .key-title {
        font-size: 14px;
        margin-bottom: 8px;
        color: #262626;
        i {
          font-size: 12px;
          color: #bfbfbf;
        }
      }
      ul li {
        display: flex;
        justify-content: space-between;
        width: 100%;
        //height: 80px;
        //line-height: 16px;
        //list-style-type: disc;
        word-break: break-word;
        margin-bottom: 40px;
        -webkit-line-clamp: 5;
        i {
          display: inline-block;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #000000;
          margin-right: 4px;
          margin-top: 5px;
        }
        p {
          //  display: inline-block;
          width: 381px;
          font-size: 14px;
          color: #262626;
          font-family: "Proxima Nova", "Roboto", sans-serif;
          margin-bottom: 0;
          //  color: #000000;
          //  font-family: "Proxima Nova", "Roboto", sans-serif;
        }
      }
    }
  }
  .demo-info-tips-bottom {
    .textarea-bottom {
      //width: 392px;
      display: flex;
      justify-content: start;
    }
    .center-demo-textarea {
      width: 450px;
      margin-right: 50px;
      font-family: "Proxima Nova", "Roboto", sans-serif;
      position: relative;
    }
    .tip-content-style {
      width: 450px;
      min-height: 96px;
      font-size: 14px;
      color: #262626;
      border: 1px solid #d9d9d9;
      padding: 8px 9px;
      font-family: "Proxima Nova", "Roboto", sans-serif;
      margin-bottom: 16px;
      //word-break: break-word;
      //-webkit-line-clamp: 4;
    }
    .demo-tip-style {
      font-size: 16px;
      margin-bottom: 8px;
      color: #000000;
    }
    .demo-tip-content {
      width: 450px;
      position: relative;
    }
  }
}
