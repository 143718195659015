
.admin-trainig-user-edit {
  font-family: Proxima Nova;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  &__left-label {
    color: #262626;
    text-align: right;
    padding-right: 10px;
  }
  &__row-margin {
    margin-bottom: 14px;
  }
}
