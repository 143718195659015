
.guest-payment-component__main-content {
  p {
    margin-bottom: 0;
  }
  .input,
  .textarea,
  .input:hover,
  .textarea:hover,
  .input:focus,
  .textarea:focus {
    border: 1px solid #f15a42;
    box-sizing: border-box;
    border-radius: 50px;
    padding: 16px 24px;

    font-size: 20px;
    line-height: 22px;
    color: #000000;

    box-shadow: none;
    height: fit-content;
  }
  .payment--form .form--footer {
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 20px;
    text-align: center;
  }

  .payment--form .form--footer button + button {
    margin-left: 25px;
  }

  .payment--form .form--title {
    text-align: left;
    color: #1a1818;
    font-size: 25px;
  }

  .payment--form .form--content {
    margin-bottom: 34px;
    font-size: 20px;
  }

  .payment--form .promo--code {
    position: relative;
  }
  .apply-coupon-code-check {
    position: absolute;
    right: 23px;
    top: 35px;
    font-size: 20px;
    color: #f15a42;
    cursor: pointer;
  }

  .payment--form .promo--code .field label {
    font-family: Niveau Grotesk;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
    color: #000000;
  }

  .payment--form .remove--code {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }

  .total--cost {
    padding: 17px;
    font-family: "Mr Eaves Mod OT";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    text-transform: uppercase;
    background-color: rgba(241, 90, 66, 0.1);
    border-radius: 100px;
    color: black;
  }

  .total--cost span:nth-child(2) {
    font-weight: 400;
    margin-left: 16px;
  }
  .ant-calendar-picker {
    width: 100%;
  }
  .ant-calendar-picker-input.ant-input {
    width: 100%;
    border: 1px solid #f15a42;
    box-sizing: border-box;
    border-radius: 50px;
    padding: 12px 24px;
    font-size: 20px;
  }
  .ant-input {
    height: auto;
  }
  .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
    border-color: #f15a42;
  }
  .control.has-icons-right .icon.is-right {
    top: 8px;
  }
}

@media screen and (max-width: 767px) {
  .payment--form .form--content {
    max-width: 300px;
  }
}
