
.nav-container .menu-items li .ant-badge .ant-badge-multiple-words {
  padding: 0 2px;
}
.nav-container .menu-items li .ant-badge .ant-badge-count {
  background: #ff6132;
  height: 18px;
  min-width: 18px;
  padding: 0 2px;
  line-height: 18px;
}
