
.confirm-dialog {
  min-height: 100px; 
  padding: 8px;
  &__msg {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #262626;
    margin-left: 18px;
  }
  &__sub-msg {
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #595959;
  }
  &__cancel-btn {
    background: #ffffff !important;
    border: 1px solid #bfbfbf !important;
    box-sizing: border-box;
    border-radius: 2px !important;
    padding: 5px 16px !important;
    margin-right: 10px;
    color: #595959;
  }
  &__confirm-btn {
    background: #4a8a5d !important;
    border: 1px solid #4a8a5d !important;
    box-sizing: border-box;
    border-radius: 2px !important;
    padding: 5px 16px !important;
    color: white;
  }
}
