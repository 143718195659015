
.identity-card__main {
  font-family: Proxima Nova;
  width: 100%;
  .card-title-img {
    width: 100%;
    height: 360px;
    border-radius: 5px;
    margin-bottom: 60px;
    position: relative;
    .header-img {
      border-radius: 5px;
      width: 100%;
      height: 0;
      padding-bottom: 39%;
      overflow: hidden;
      background-position: center center;
      background-repeat: no-repeat;
      -webkit-background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      background-size: 100% 100%;
    }
    .position-header {
      width: 96px;
      height: 96px;
      border-radius: 50%;
      position: absolute;
      bottom: -48px;
      border: 1px solid #8c8c8c;
      left: 12px;
      z-index: 3;
      -webkit-box-shadow: 0 3px 1px 1px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
      box-shadow: 0 3px 3px 0px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
        0 1px 5px 0 rgba(0, 0, 0, 0.12);
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
    .edit-profile-info {
      width: 48px;
      height: 48px;
      text-align: center;
      line-height: 48px;
      border-radius: 50%;
      font-size: 18px;
      background: white;
      position: absolute;
      top: 17px;
      right: 16px;
      z-index: 3;
      .edit-icon-style {
        color: #4a8a5d;
      }
    }
    .edit-profile-info:hover {
      background: #4a8a5d;
      cursor: pointer;
      .edit-icon-style {
        color: white;
      }
    }
  }
  .card-content-text {
    width: 100%;
    .card-name {
      font-family: Poppins;
      font-weight: 600;
      font-size: 26px;
      line-height: 38px;
      color: #262626;
      height: 50px;
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }
    .card-name-introduce {
      font-family: Proxima Nova;
      width: 64%;
      font-style: normal;
      font-size: 16px;
      line-height: 24px;
      color: #595959;
      word-wrap: break-word;
      overflow: hidden;
      display: flex;
      align-items: center;
    }
    .card-country {
      font-family: Proxima Nova;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: #262626;
      margin-top: 11px;
      margin-bottom: 20px;
    }
    .card-button {
      .visit-button {
        background: #4a8a5d;
        color: white;
        margin-right: 13px;
      }
      .email-button {
        background: #f5f5f5;
      }
      .email-button-click {
        background: #4a8a5d;
        color: white;
      }
    }
    .card-tag-text {
      position: relative;
      margin-bottom: 8px;
    }
    .retailer-profile__icons {
      width: 129px;
      display: flex;
      align-items: center;
      position: absolute;
      top: -50px;
      right: 0;
      direction: rtl;
      img {
        margin-left: 16px;
        margin-top: 20px;
      }
    }
  }
  .ant-tag {
    background: #f5f5f5 !important;
    border-radius: 2px !important;
    margin-right: 16px !important;
  }
  .flag-margin-right {
    margin-right: 10px;
  }
  .flag-icon {
    width: 24px !important;
  }
  .no-img-style {
    background: rgba(0, 0, 0, 0.1);
  }
}
