
.beauty-fluent-step-two-com__main {
  p {
    margin: 0;
  }
  .three-select-billing {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .beauty-fluent-select {
    width: 307px;
    height: 430px;
    border: 1px solid #f15a42;
    color: #1a1818;
    overflow: hidden;
    position: relative;
    .saving-style {
      width: 192px;
      height: 192px;
      border-radius: 50%;
      background: #fac92c;
      text-align: left;
      color: #ffffff;
      position: absolute;
      top: -76px;
      right: -61px;
      z-index: 5;
      span {
        display: inline-block;
        margin-left: 30px;
        margin-top: 120px;
        font-size: 14px;
        font-family: "Proxima Nova", "Roboto", sans-serif;
      }
    }
    .title-plan {
      width: 99px;
      margin: 32px;
      text-align: left;
      font-family: "Niveau Grotesk";
      font-size: 18px;
      font-weight: bold;
    }
    .title-plan-click {
      margin: 32px 32px 56px 32px;
    }
  }
  .click-beauty-fluent-select {
    height: 477px;
    box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.1);
    background: rgba(237, 142, 131, 0.2);
  }
  .beauty-fluent-select-main:nth-child(2) {
    margin: 0 32px;
  }
  .beauty-fluent-select-main {
    position: relative;
  }
  .price-content {
    height: 180px;
    margin-left: 32px;
    margin-bottom: 50px;
    padding-top: 34px;
    text-align: left;
    font-family: DMSans;
  }
  .save-up-style {
    display: inline-block;
    //width: 142px;
    //height: 24px;
    text-align: center;
    padding: 3px 10px;
    margin-top: 20px;
    border: 1px solid #f15a42;
    border-radius: 30px;
    color: #f15a42;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 700;
  }
  .choose-plan-button {
    //margin: 50px 0 0 36px;
    //margin-top: 55px;
    margin-left: 36px;
    p {
      width: 227px;
      height: 55px;
      line-height: 55px;
      font-size: 18px;
      background: #f15a42;
      border-radius: 50px;
      color: #ffffff;
      font-weight: bold;
      cursor: pointer;
    }
  }
  .top-line-style {
    width: 307px;
    height: 30px;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
    font-family: "Proxima Nova", "Roboto", sans-serif;
    font-weight: bold;
    background: #1b4793;
    position: absolute;
    top: -30px;
    left: 0;
  }
}
