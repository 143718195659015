
.beauty-fluent-step-three__main {
  margin: 0 auto;
  padding-top: 30px;

  .step-three-com-style {
    display: flex;
    justify-content: space-between;
  }

  .step-title {
    width: 316px;
    text-align: center;
    margin: 20px auto 60px;
    display: flex;
    justify-content: space-between;
    position: relative;

    .step-line {
      height: 3px;
      width: 316px;
      background-color: #f15a42;
      position: absolute;
      top: 15px;
      left: 0;
      z-index: 1;
    }
  }

  .submit-style {
    width: 480px;
    line-height: 57px;
    height: 57px;
    text-align: center;
    border-radius: 50px;
    background-color: #f15a42;
    color: #ffffff;
    margin-top: 45px;
    font-size: 18px;
    font-family: "Niveau Grotesk";
    //font-weight: 700;
    cursor: pointer;
    margin-bottom: 29px;
  }

  .cancel-style {
    width: 480px;
    text-align: center;
    font-size: 18px;
    font-family: DMSans;
    cursor: pointer;
  }

  .step-icon-style {
    position: absolute;
    top: -25px;
    left: -7px;
    color: #1a1818;
    font-weight: 500;
    font-family: DMSans;
  }

  .billing-style {
    left: 129px;
  }

  .payment-style {
    left: 260px;
    color: #f27c59;
  }
}
