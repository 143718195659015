
.admin-training-landing-green-btn {
  border: none !important;
  background-color: #4a8a5d !important;
  color: white !important;
}
.admin-training-landing-green-btn.ant-btn:hover,
.admin-training-landing-green-btn.ant-btn:active,
.admin-training-landing-green-btn.ant-btn:focus {
  border: none !important;
  background-color: #4a8a5d !important;
  color: white !important;
}
.admin-training-landing-red-btn {
  border: none !important;
  background-color: #e19387 !important;
  color: white !important;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.04em;
  border-radius: 7px;
}
.admin-training-landing-red-border-btn {
  background: #ffffff !important;
  border: 1px solid #e1644c !important;
  border-radius: 7px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.04em;
  color: #e1644c !important;
}
.admin-training-landing-light-green-btn {
  background: #a0d29b !important;
  border-radius: 7px;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff !important;
}
.admin-training-tabs1.ant-tabs.ant-tabs-line .ant-tabs-bar .ant-tabs-tab-active.ant-tabs-tab {
  background: #3a77a9;
  color: white;
  font-family: "Proxima Nova";
  font-size: 14px;
  height: 30px;
  width: 137px;
  text-align: center;
  line-height: 30px;
  border-radius: 7px 7px 0px 0px;
  padding: 0px;
  margin-right: 5px;
}

.admin-training-tabs1.ant-tabs.ant-tabs-line .ant-tabs-bar .ant-tabs-tab {
  background: #d9d9d9;
  color: white;
  font-family: "Proxima Nova";
  font-size: 14px;
  height: 30px;
  width: 137px;
  text-align: center;
  line-height: 30px;
  border-radius: 7px 7px 0px 0px;
  padding: 0px;
  margin-right: 5px;
}
.admin-training-tabs1.ant-tabs.ant-tabs-line .ant-tabs-bar .ant-tabs-nav-container {
  height: 30px;
}
.admin-training-tabs1 .ant-tabs-ink-bar {
  color: transparent;
  background-color: transparent;
}
.admin-training-tabs li {
  background: #d9d9d9;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 30px;
  width: 137px;
  text-align: center;
  line-height: 30px;
  border-radius: 7px 7px 0px 0px;
  margin-right: 5px;
  display: inline-block;
  color: white;
  cursor: pointer;
}
.admin-training-tabs li a {
  color: white;
}
.admin-training-breadcrumb.ant-breadcrumb {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.04em;
  margin-bottom: 30px;
}
.admin-training-breadcrumb-content {
  cursor: pointer;
}
.admin-training-breadcrumb-content:hover {
  color: #40a9ff;
}
.admin-training-switch.ant-switch {
  background-color: #f27c59;
  background-image: none !important;
}
.admin-training-switch.ant-switch-checked {
  background-color: #9fd19a;
}
.admin-training-table {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
}
.admin-training-table .admin-training-table-striped td {
  background-color: rgba(211, 228, 206, 0.06);
}
.admin-training-table .ant-table-thead > tr > th {
  background: transparent;
}
.admin-training-table
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  background-color: transparent;
}
.admin-training-table
  .ant-table-middle
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-thead
  > tr
  > th {
  color: #262626;
  letter-spacing: 0.04em;
}
.admin-training-table
  .ant-table-middle
  > .ant-table-content
  > .ant-table-body
  > table
  > .ant-table-tbody
  > tr
  > td {
  color: #000;
  letter-spacing: 0.04em;
}
.admin-modal-title {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  display: block;
  align-items: center;
  text-align: center;
  letter-spacing: 0.04em;
  color: #1a1818;
  margin-bottom: 30px;
}
.admin-modal-content {
  margin-left: 20px;
  margin-right: 20px;
}
.admin-modal-content .ant-form-item {
  margin-bottom: 8px;
}
.admin-modal-click-link-text {
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.04em;
  text-decoration-line: underline;
  color: #f27c59;
  cursor: pointer !important;
}
.admin-training-landing-deep-red-btn {
  border: none !important;
  background-color: #f27c59 !important;
  color: white !important;
  font-family: "Proxima Nova";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.04em;
  border-radius: 7px;
}
.admin-modal-content .admin-training-landing-deep-red-btn {
  margin-top: 20px;
  width: 48%;
}
.admin-modal-content .admin-training-landing-light-green-btn {
  margin-top: 20px;
  margin-left: 8px;
  width: 48%;
}
.admin-training-table .ant-table.ant-table-middle .ant-table-thead .ant-table-column-sorters {
  padding: 0px;
}
.admin-training-switch.ant-switch.ant-switch-disabled {
  background: #8c8c8c;
}
.admin-training-switch.ant-switch.ant-switch-disabled::after {
  opacity: 0.5;
}
.admin-training-active-green {
  width: 18px;
  height: 18px;
  border-radius: 12px;
  background: #9fd19a;
}
.admin-training-deactive-red {
  width: 18px;
  height: 18px;
  border-radius: 12px;
  background: #f27c59;
}
.admin-training-deactive-gray {
  width: 18px;
  height: 18px;
  border-radius: 12px;
  background: #8c8c8c;
}
.admin-training-sort-gray {
  color: #8c8c8c !important;
}
.admin-training-sort-active {
  color: #3977a9 !important;
}
.admin-brand-url {
  cursor: pointer;
}
.admin-brand-url:hover {
  text-decoration: underline;
}
